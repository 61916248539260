var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{ref:"sidebar_create_redirection",attrs:{"id":"sidebar-right-redirections","visible":_vm.showOpenRedirect,"bg-variant":"white","right":"","backdrop":"","shadow":"","no-header":""},on:{"change":function (val) { return _vm.$emit('update:show-open-redirect', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50",staticStyle:{"background-color":"#F0F1F7","height":"91px"}},[_c('b-card',{staticClass:"overflow-hidden w-100",staticStyle:{"background-color":"#F0F1F7"},attrs:{"no-body":""}},[_c('h1',{staticStyle:{"margin-top":"30px"}},[_vm._v("Create redirection")])]),_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"XIcon","size":"20"},on:{"click":hide}})],1),_c('div',{staticClass:"b-sidebar-body mt-1",staticStyle:{"padding":"20px"}},[_c('validation-observer',{ref:"rulesForm"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticStyle:{"font-weight":"700"},attrs:{"label":"Source"}},[_c('validation-provider',{attrs:{"name":"Source","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"font-weight":"400","border-radius":"10px","width":"100%"},attrs:{"placeholder":"Source","state":errors.length > 0 ? false:null},model:{value:(_vm.source),callback:function ($$v) {_vm.source=$$v},expression:"source"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticStyle:{"font-weight":"700"},attrs:{"label":"Target"}},[_c('validation-provider',{attrs:{"name":"Target","rules":"required|urlTarget"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"font-weight":"400","border-radius":"10px","width":"100%"},attrs:{"placeholder":"Target","state":errors.length > 0 ? false:null},model:{value:(_vm.target),callback:function ($$v) {_vm.target=$$v},expression:"target"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticStyle:{"font-weight":"700"},attrs:{"label":"Comment"}},[_c('validation-provider',{attrs:{"name":"Comment","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{staticStyle:{"font-weight":"400","border-radius":"10px","width":"100%"},attrs:{"placeholder":"Comment","state":errors.length > 0 ? false:null},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-button',{staticClass:"mt-2",staticStyle:{"background":"#7838ff !important","border":"1px solid #7838ff !important","width":"158px","height":"46px","border-radius":"23px"},attrs:{"variant":"primary","disabled":_vm.processingSave},on:{"click":_vm.validationForm}},[_c('span',{staticClass:"align-middle",staticStyle:{"font-weight":"700","color":"#FFFFFF"}},[_vm._v("Save")])])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }