<template>
    <div>
      <create-redirection
        :show-open-redirect.sync="showOpenRedirect"
        :idsite="idsite"
      />
      <register-sidebar :show-open-register-sidebar.sync="showOpenRegisterSidebar" />
      <add-site-project :show-open-add-site-project.sync="showOpenAddSiteProject" :idsite="siteObj.length >= 1 ? siteObj[0] : ''" />
      
      <b-row class="mt-25 tabs-responsive">
        <b-col md="12">
            <b-button
                variant="info"
                class=""
                style="border-radius: 50px; vertical-align: middle; display: flex; position: absolute; right: 10px; top: -10px;"
                @click="$route.name === 'shared-site' && !userObj ? openRegister() : $route.name === 'shared-site' && userObj ? showOpenAddSiteProject = true : showOpenRedirect = true"
            >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">Create</span>
            </b-button>
            <h1>Redirections</h1>
            <div style="text-align: center;" v-if="loading">
                <b-spinner
                    class="mt-2"
                    style="color: #bbb !important;"
                />
            </div>
            <div class="top-scroll-horizontal-tabs-responsive" style="overflow-x: scroll;"><div class="div1" id="tab-err-sc2" style=" height: 20px;"></div></div>
            <b-table-simple
                small
                responsive
                id="tab-err-sc1"
                class="vgt-table tableErrors"
                :style="'margin-bottom: 60px !important;'"
                v-if="arrayInfiniteData.length >= 1 && !loading"
            >
                <b-thead>
                    <b-tr>
                        <!-- <b-th class="thProTableErrors" style="max-width: 82px !important; width: 82px !important; min-width: 82px !important;">AI</b-th> -->
                        <!-- <b-th class="thPageTableErrors">Page</b-th> -->
                        <b-th class="thPageTableErrors">Target</b-th>
                        <b-th class="thPageTableErrors">Source</b-th>
                        <b-th class="thPageTableErrors">Comment</b-th>
                        <!-- <b-th class="thStatusTableErrors">Status</b-th> -->
                        <!-- <b-th class="thImgTableErrors">Image</b-th>
                        <b-th class="thTableResponsiveIssue">Issue</b-th>
                        <b-th class="">Action</b-th> -->
                    </b-tr>
                </b-thead>
                <b-tbody v-if="arrayInfiniteData.length === 0">
                    <b-tr>
                    <b-td>
                        No pages
                    </b-td>
                    </b-tr>
                </b-tbody>
                <b-tbody v-for="(dataList, index) in arrayInfiniteData" :key="index" :style="index % 2 ? 'background: #fff !important;' : 'background: rgb(240, 241, 243) !important;'" v-else>
                    <b-tr>
                        <td>{{dataList.target}}</td>
                        <td>{{dataList.source}}</td>
                        <td>{{dataList.comment}}</td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-col>
      </b-row>
    </div>
  </template>
  
  <script>
  import {
    BButton, BTab, BCard, BCardHeader, BRow, BCol, BCardText, BCardBody, BAvatar, BTabs, BTd, BTbody, BTableSimple, BThead, BImgLazy, BTh, BTr, BBadge, BSpinner
  } from 'bootstrap-vue'
//   import InfoResponsive from '@/views/extensions/acl/table/sidebar/InfoResponsive.vue'
  import CreateRedirection from '@/views/extensions/acl/table/sidebar/CreateRedirection.vue'
  import RegisterSidebar from '@/views/pages/authentication/RegisterSidebar.vue'
  import AddSiteProject from '@/views/extensions/acl/table/sidebar/AddSiteProject.vue'
  import VueApexCharts from 'vue-apexcharts'
  import { $themeColors } from '@themeConfig'
  import { db, auth, firebase, storage, dbErrors } from '@/firebase'
  
  export default {
    name: 'Performance',
    components: {
      BButton,
      BTabs,
      BTab,
      BCard,
      BCardHeader,
      BRow,
      BCol,
      BCardText,
      BCardBody,
      BAvatar,
      BTd,
      BTbody,
      BTableSimple,
      BThead,
      BTh,
      BTr,
      BImgLazy,
      BBadge,
      BSpinner,
      VueApexCharts,
    //   InfoResponsive,
      CreateRedirection,
      RegisterSidebar,
      AddSiteProject,
    },
    props: [],
    data() {
      return {
          loading: true,
          showOpenRegisterSidebar: false,
          showOpenAddSiteProject: false,
          proIcon: require('@/assets/images/icons/i-pro-badge.svg'),
          proIconNone: require('@/assets/images/icons/i-pro-badge-none.svg'),
          Mobile: true,
          Desktop: false,
          History: false,
          showOpenRedirect: false,
          typeSelectedResponsive: '',
          dataResponsive: '',
          arrayInfiniteData: [],
          timerInfinite: null,
          loadingInfiniteScroll: false,
          arrayInfiniteDataFilter: [],
          allData: [],
          idsite: '',
          userObj: '',
          siteObj: []
      }
    },
    watch: {
    },
    methods: {
      infiniteScroll() {
        var listElm = document.querySelector('#app');
        let bottomOfWindow = document.documentElement.scrollTop + listElm.clientHeight >= listElm.scrollHeight - 200
        // if(this.selectedFilteredPages.length === 0) {
          if(this.allData.length >= 1 && this.active) {
            if(bottomOfWindow) {
              if(!this.loadingInfiniteScroll && this.allData.length !== this.arrayInfiniteData.length) {
                // console.log('first')
                this.loadingInfiniteScroll = true
                var counterFound = 0
                var arrayInfinite = []
                this.allData.forEach(ele => {
                  var alreadyAdd = arrayInfinite.filter(item => item.idPageOverview === ele.idPageOverview && item.page === ele.page)
                  if(alreadyAdd.length === 0) {
                    if(counterFound <= 10) {
                      counterFound += 1
                      arrayInfinite.push(ele)
                    }
                  }
                })
                this.arrayInfiniteData = arrayInfinite
  
                setTimeout(() => {
                  this.loadingInfiniteScroll = false
                }, 1000)
              }
            }
          }
        // }
      },
      openRegister() {
        // console.log('open register')
        this.showOpenRegisterSidebar = true
      },
    },
    mounted() {
      this.timerInfinite = setInterval(() => {
        this.infiniteScroll()
      }, 600)
    },
    destroyed() {
      clearInterval(this.timerInfinite)
    },
    created() {
      this.loading = true
      this.arrayInfiniteData = []
      this.timerInfinite = null
      this.loadingInfiniteScroll = true
      this.showOpenRegisterSidebar = false
      this.showOpenAddSiteProject = false

      var user = auth.currentUser
      var atomUser = ''
      if(user && user.uid) {
        this.userObj = user.uid
        atomUser = user.uid
      } else {
        this.userObj = ''
        atomUser = ''
      }

      if(this.$route.name === 'shared-site') {
        db.collection('Sites').where('WebsiteURL', '==', this.$route.query.site).limit(1).get()
        .then(siteQueryWebsite => {
          if(siteQueryWebsite.size) {
            siteQueryWebsite.forEach(site => {
              if(site.id) {
                this.siteObj = [{
                  imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                  name: site.data().name ? site.data().name : site.data().homepage,
                  nameFixed: site.data().name ? site.data().name : site.data().WebsiteURL,
                  domain: site.data().WebsiteURL,
                  domainFix: site.data().homepage,
                  currentErrors: 0,
                  fixedIssues: '79',
                  key: site.id,
                  alerts: '3',
                  membership: `${site.data().plan ? site.data().plan : 'Free'} license`,
                  ownedBy: site.data().owner && site.data().owner.uid ? site.data().owner : '',
                  id: site.id,
                  owner: 'owner',
                  permission: site.data().owner && site.data().owner.uid === atomUser ? 'owner' : 'edit',
                  projectid: ''
                }]

                db.collection('Redirections').where('idsite', '==', site.id).onSnapshot(docs => {
                  this.idsite = site.id
                  var atomArray = []
                  docs.forEach(ele => {
                    if(atomArray.filter(item => item.id === ele.id).length === 0) {
                      // console.log(ele.data());
                      atomArray.push({
                        id: ele.id,
                        target: ele.data().target,
                        source: ele.data().source,
                        comment: ele.data().comment
                      })
                    }
                  })
                  this.allData = atomArray

                  if(atomArray && atomArray.length) {
                    var counter = 0
                    atomArray.forEach(element => {
                      counter += 1
                      if(counter <= 3 && this.arrayInfiniteData.filter(ele => ele.id === element.id).length === 0) {
                        this.arrayInfiniteData.push(element)
                      }
                    });
            
                    setTimeout(() => {
                      this.loadingInfiniteScroll = false
                    }, 1000)
                  }
                })
              }
              
              setTimeout(() => {
                this.loading = false
              }, 2500)
            })
          }
        })
      } else {
        db.collection('Sites').doc(this.$route.params.id).get()
        .then(site => {
          if(site.id) {
            this.siteObj = [{
              imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
              name: site.data().name ? site.data().name : site.data().homepage,
              nameFixed: site.data().name ? site.data().name : site.data().WebsiteURL,
              domain: site.data().WebsiteURL,
              domainFix: site.data().homepage,
              currentErrors: 0,
              fixedIssues: '79',
              key: site.id,
              alerts: '3',
              membership: `${site.data().plan ? site.data().plan : 'Free'} license`,
              ownedBy: site.data().owner && site.data().owner.uid ? site.data().owner : '',
              id: site.id,
              owner: 'owner',
              permission: site.data().owner && site.data().owner.uid === atomUser ? 'owner' : 'edit',
              projectid: ''
            }]

            db.collection('Redirections').where('idsite', '==', site.id).onSnapshot(docs => {
              this.idsite = site.id
              var atomArray = []
              docs.forEach(ele => {
                if(atomArray.filter(item => item.id === ele.id).length === 0) {
                  // console.log(ele.data());
                  atomArray.push({
                    id: ele.id,
                    target: ele.data().target,
                    source: ele.data().source,
                    comment: ele.data().comment
                  })
                }
              })
              this.allData = atomArray

              if(atomArray && atomArray.length) {
                var counter = 0
                atomArray.forEach(element => {
                  counter += 1
                  if(counter <= 3 && this.arrayInfiniteData.filter(ele => ele.id === element.id).length === 0) {
                    this.arrayInfiniteData.push(element)
                  }
                });
        
                setTimeout(() => {
                  this.loadingInfiniteScroll = false
                }, 1000)
              }
            })
          }
          setTimeout(() => {
            this.loading = false
          }, 2500)
        })
      }
    }
  }
  </script>
  
  <style>
  .content-comment-responsive-td p {
    border-bottom: 10px !important;
    margin-bottom: 0px !important;
  }
  .thTableResponsiveIssue {
    min-width: 160px;
  }
  .tdTableResponsiveIssue {
    min-width: 160px;
  }
  </style>