<template>
  <div>
    <b-sidebar
      id="sidebar-right-subscription"
      ref="sidebar_info_subscription"
      :visible="showOpenSubscription"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open-subscription', val)"
    >
      <template #default="{ hide }">
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50"
          style="background-color: #F0F1F7; height: 91px;"
        >
          <b-card
            no-body
            class="overflow-hidden w-100"
            style="background-color: #F0F1F7;"
          >
            <header-sidebar :idsite-header.sync="idsite" />
          </b-card>
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="20"
            @click="hide"
          />
        </div>
        <div class="ml-3 my-2">
          <p
            v-if="idsite.label"
            class="mb-0"
            style="font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 14px;"
          >
            Project
          </p>
          <h4
            v-if="idsite.label"
            class=""
            style="font-weight: 700;"
          >
            {{ idsite.label }}
          </h4>
        </div>

        <b-card
          no-body
          class="overflow-hidden w-100 mt-0"
          style="background-color: transparent;"
        >
          <b-row
            no-gutters
            class="mt-0 mx-1"
          >
            <b-col
              md="12"
              class="m-auto"
            >
              <feather-icon
                size="21"
                icon="ArrowLeftIcon"
                class="cursor-pointer"
                style="margin-bottom: 25px; margin-left: 10px;"
                @click="hide"
              />

              <h3 style="" class="mt-1">Current license: <span style="font-weight: 400 !important;">{{!planType ? 'free' : planType === 5 ? '5 pages' : planType === 10 ? '10 pages' : planType === 25 ? '25 pages' : planType + ' pages'}}</span></h3>
              <b-badge class="mt-50 cursor-pointer" style="font-size: 11px;" variant="light-dark" v-if="infoLicense.freeTrial === true && infoLicense.subscriptionId">
                <span style="color: #646464 !important">On trial</span>
              </b-badge>
              <p style="margin-bottom: 7px;" class="mt-1"><b>Current pages: </b>{{pages ? pages : '2'}}</p>
              <p style="margin-bottom: 7px;"><b>Price: </b> {{ !planType ? 'free' : getPlanAmount(paidRecurrency, planType, planType) }}{{ !planType ? '' : paidRecurrency === 'month' || paidRecurrency === 'Month' ? ' / month' : ' / month billed anually' }}</p>
              <p style="margin-bottom: 7px;"><b>Next due: </b>{{ infoLicense.freeTrial === true && infoLicense.subscriptionId ? infoLicense.dateTrial : nextDue ? nextDue : '-'}}</p>
              <span class="mr-50"><b>Status:</b> </span>
              <div v-if="cancelSub === true && nextDue && subscriptionId">
                <b-badge variant="light-warning">
                  Canceled
                </b-badge>
                <b-badge class="mt-50 ml-75" style="font-size: 11px;" variant="light-dark" v-if="nextDue !== '-'">
                    <span style="color: #646464 !important">Cancels {{nextDueCancel}}</span>
                    <feather-icon
                        icon="ClockIcon"
                        class="ml-25"
                        style="padding-bottom: 1px;"
                    />
                </b-badge>
              </div>
              <b-badge variant="light-success" v-else>
                  Active
              </b-badge>

              <div v-if="cancelSub === true && nextDue && subscriptionId">
                <div class="w-100 d-block">
                  <b-button
                    :style="'background: #7838ff !important; border: 1px solid #7838ff !important; width: 188px; height: 46px; border-radius: 23px; margin-top: 30px !important;'"
                    class="ml-0"
                    variant="primary"
                    @click="getInfoReactivateLicense()"
                  >Reactivate
                  </b-button>
                </div>
              </div>
              <div v-else>
                <!-- <div class="w-100 d-block" v-if="infoLicense.subscriptionId && infoLicense.plan !== 'Free'">
                  <b-overlay
                    :show="processInvoice"
                    :variant="'transparent'"
                    :opacity="0.50"
                    :blur="'2px'"
                    rounded="sm"
                  >
                    <b-link
                      :class="!processInvoice && infoLicense.customerId ? '' : 'disabled-link'"
                      @click.prevent="!processInvoice && infoLicense.customerId ? redirectToBilling(infoLicense.customerId) : null"
                      :style="infoLicense.customerId ? (processInvoice ? 'cursor: progress; color: #c1c1c1 !important; padding-left: 46px; padding-top: 20px; display: block;' : 'padding-left: 46px; padding-top: 20px; display: block;') : 'padding-left: 46px; padding-top: 20px; display: block;'"
                    >
                      View invoices
                    </b-link>
                  </b-overlay>
                </div> -->

                <div class="w-100 d-block">
                  <b-button
                    :style="'background: #7838ff !important; border: 1px solid #7838ff !important; width: 188px; height: 46px; border-radius: 23px; margin-top: 30px !important;'"
                    class="ml-0"
                    variant="primary"
                    :disabled="processInvoice"
                    @click="getInfoLicense()"
                  >Upgrade/Downgrade
                  </b-button>
                </div>

                <div class="w-100 d-block" v-if="infoLicense.subscriptionId && infoLicense.plan !== 'Free'">
                  <b-button
                    :style="'width: 188px; height: 46px; border-radius: 23px; margin-top: 15px !important;'"
                    class="ml-0"
                    variant="outline-warning"
                    :disabled="processInvoice"
                    @click="getInfoCancelLicense()"
                  >{{ infoLicense.freeTrial === true && infoLicense.subscriptionId ? 'Cancel Free Trial' : 'Cancel license' }}
                  </b-button>
                </div>
              </div>
              
              <div class="mb-2 mt-2 py-2">
                <span style="padding-bottom: 15px; border-bottom: 1px solid rgb(240, 241, 247); font-size: 16px; font-weight: 500; width: 100%; margin-bottom: 10px; display: block;">PAYMENT METHODS</span>
                <b-overlay
                  id="overlay-background"
                  :show="loadingInvoices"
                  :variant="variant"
                  :opacity="opacity"
                  :blur="blur"
                  rounded="sm"
                  :class="loadingInvoices ? 'pt-3' : ''"
                >
                  <table v-if="arrayPayments.length && !loadingInvoices">
                    <tr v-for="(item, index) in arrayPayments" :key="index">
                      <td style="padding: 18px 3px; color: #353a44; font-size: 15px; min-width: 250px; max-width: 250px;">
                        <div style="display: flex;">
                          <svg class="SVGInline-svg SVGInline--cleaned-svg SVG-svg BrandIcon-svg" height="24" width="24" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" v-if="item.brand === 'visa'"><g fill="none" fill-rule="evenodd"><path d="M0 0h32v32H0z" fill="#00579f"></path><g fill="#fff" fill-rule="nonzero"><path d="M13.823 19.876H11.8l1.265-7.736h2.023zm7.334-7.546a5.036 5.036 0 0 0-1.814-.33c-1.998 0-3.405 1.053-3.414 2.56-.016 1.11 1.007 1.728 1.773 2.098.783.379 1.05.626 1.05.963-.009.518-.633.757-1.216.757-.808 0-1.24-.123-1.898-.411l-.267-.124-.283 1.737c.475.213 1.349.403 2.257.411 2.123 0 3.505-1.037 3.521-2.641.008-.881-.532-1.556-1.698-2.107-.708-.354-1.141-.593-1.141-.955.008-.33.366-.667 1.165-.667a3.471 3.471 0 0 1 1.507.297l.183.082zm2.69 4.806.807-2.165c-.008.017.167-.452.266-.74l.142.666s.383 1.852.466 2.239h-1.682zm2.497-4.996h-1.565c-.483 0-.85.14-1.058.642l-3.005 7.094h2.123l.425-1.16h2.597c.059.271.242 1.16.242 1.16h1.873zm-16.234 0-1.982 5.275-.216-1.07c-.366-1.234-1.515-2.575-2.797-3.242l1.815 6.765h2.14l3.18-7.728z"></path><path d="M6.289 12.14H3.033L3 12.297c2.54.641 4.221 2.189 4.912 4.049l-.708-3.556c-.116-.494-.474-.633-.915-.65z"></path></g></g></svg>
                          <svg class="SVGInline-svg SVGInline--cleaned-svg SVG-svg BrandIcon-svg" height="24" width="24" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" v-else><g fill="none" fill-rule="evenodd"><path d="M0 0h32v32H0z" fill="#000"></path><g fill-rule="nonzero"><path d="M13.02 10.505h5.923v10.857H13.02z" fill="#ff5f00"></path><path d="M13.396 15.935a6.944 6.944 0 0 1 2.585-5.43c-2.775-2.224-6.76-1.9-9.156.745s-2.395 6.723 0 9.368 6.38 2.969 9.156.744a6.944 6.944 0 0 1-2.585-5.427z" fill="#eb001b"></path><path d="M26.934 15.935c0 2.643-1.48 5.054-3.81 6.21s-5.105.851-7.143-.783a6.955 6.955 0 0 0 2.587-5.428c0-2.118-.954-4.12-2.587-5.429 2.038-1.633 4.81-1.937 7.142-.782s3.811 3.566 3.811 6.21z" fill="#f79e1b"></path></g></g></svg>
                          <b style="text-transform: uppercase; margin-left: 3px; margin-right: 3px;">{{ item.brand }}</b>{{ item.last4 }}
                        </div>
                      </td>
                      <td style="padding: 10px 3px; color: #353a44; font-size: 15px; min-width: 180px; max-width: 180px; text-align: center;">
                        <span>Expires {{ item.exp_month }}/{{ item.exp_year }}</span>
                      </td>
                    </tr>
                  </table>

                  <b-button
                    v-if="arrayPayments.length && !loadingInvoices"
                    :style="'background: transparent !important; border: 1px solid transparent !important; width: 288px; height: 46px; border-radius: 23px; margin-top: 3px !important; color: rgb(53, 58, 68) !important; text-align: left; margin-bottom: 20px; padding-left: 0;'"
                    class="ml-0 mt-1"
                    variant="primary"
                    :disabled="showUpdateCards ? true : processSubmitCard ? true : false"
                    @click="showUpdateCards = true"
                  >
                  <feather-icon
                    class="cursor-pointer"
                    icon="PlusIcon"
                    size="20"
                    style="margin-right: 5px; position: relative; top: 3px;"
                  />
                  Add payment method
                  </b-button>
                </b-overlay>
              </div>

              <div class="mb-2 pb-4">
                <span style="padding-bottom: 15px; border-bottom: 1px solid rgb(240, 241, 247); font-size: 16px; font-weight: 500; width: 100%; margin-bottom: 10px; display: block;">INVOICE HISTORY</span>
                <b-overlay
                  id="overlay-background"
                  :show="loadingInvoices"
                  :variant="variant"
                  :opacity="opacity"
                  :blur="blur"
                  rounded="sm"
                  :class="loadingInvoices ? 'pt-3' : ''"
                >
                  <table v-if="arrayInvoices.length && !loadingInvoices" class="mb-3">
                    <tr v-for="(item, index) in arrayInvoices" :key="index">
                      <a :href="item.url" target="_blank" style="height: 100%; display: block; cursor: alias;" class="link-invoice-info-subscription">
                        <td style="padding: 10px 3px; color: #353a44; font-size: 15px; min-width: 100px; max-width: 100px;">{{ item.created }}</td>
                        <td style="padding: 10px 3px; color: #353a44; font-size: 15px; min-width: 70px; max-width: 70px; text-align: center;">{{ item.amount }}</td>
                        <td style="padding: 10px 3px; font-size: 15px; text-align: center; min-width: 80px; max-width: 80px;">
                          <b-badge variant="light-success" v-if="item.paid">
                            Paid
                          </b-badge>
                          <b-badge variant="light-danger" v-else>
                            Error
                          </b-badge>
                        </td>
                        <td style="padding: 10px 3px; color: #353a44; font-size: 15px; min-width: 280px; max-width: 280px;"><span>{{ item.detail }}</span></td>
                      </a>
                    </tr>
                  </table>
                  <p style="text-align: center;" v-else-if="arrayInvoices.length === 0 && !loadingInvoices">No invoice history</p>
                </b-overlay>
              </div>
            </b-col>
          </b-row>
        </b-card>

        <b-modal
          :title="'Add Credit card information'"
          v-model="showUpdateCards"
          hide-footer
        >
          <div>
            <feather-icon
              class="cursor-pointer"
              icon="ArrowLeftIcon"
              size="20"
              style="margin-bottom: 20px;"
              @click="showUpdateCards = false"
            />

            <form @submit.prevent="handleSubmitCC">
              <div id="card-element"></div>
              <button
                class="mt-2"
                type="submit"
                :style="processSubmitCard ? 'opacity: 0.5; cursor: no-drop; border: 1px solid #7838FF !important; background: #7838ff !important; border-radius: 13px; padding: 0.786rem 1.5rem; color: #fff !important; float: right !important; font-size: 1rem;' : 'border: 1px solid #7838FF !important; background: #7838ff !important; border-radius: 13px; padding: 0.786rem 1.5rem; color: #fff !important; float: right !important; font-size: 1rem;'"
                :disabled="processSubmitCard"
              >Add Payment Method</button>
            </form>
          </div>
        </b-modal>
        
        <help-footer />
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BListGroup, BListGroupItem, BImgLazy, BBadge, BModal,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow, BLink, BOverlay,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, minLength, password, confirmed, email,
} from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import HelpFooter from '@/views/components/footer/HelpFooter'
import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { loadStripe } from '@stripe/stripe-js'
import { mapActions } from 'vuex'
import countries from '@/@fake-db/data/other/countries'
import moment from "moment";
import store from '@/store'
import { db, auth } from '@/firebase'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BImgLazy,
    BBadge,
    BLink,
    BOverlay,
    BModal,
    HelpFooter,
    HeaderSidebar,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpenSubscription',
    event: 'update:show-open-subscription',
  },
  props: ['idsite','showOpenSubscription'],
  data() {
    return {
      email,
      required,
      password,
      confirmed,
      userData: '',
      variant: 'transparent',
      opacity: 0.85,
      blur: '2px',

      planType: '',
      planSite: '',
      paidRecurrency: '',
      nextDue: '',
      nextDueCancel: '',
      cancelSub: '',
      subscriptionId: '',
      pages: '',
      downgrade_planed: '',
      infoLicense: '',
      processInvoice: false,

      //   sources
      appImgComingSoon: require('@/assets/images/icons/logo-coming-soon.svg'),

      // invoices
      loadingInvoices: false,
      arrayInvoices: [],

      // payments
      arrayPayments: [],
      processSubmitCard: false,
      showUpdateCards: false,
      stripe: null,
      cardElement: null,
    }
  },
  watch: {
    showUpdateCards(val) {
      if(val) {
        this.loadStripeCard()
      }
    },
    showOpenSubscription(val) {
      this.infoLicense = ''
      this.processSubmitCard = false
      this.processInvoice = false
      this.showUpdateCards = false
      if(val) {
        document.body.style.overflow = 'hidden'

        const user = auth.currentUser
        db.collection('Users').doc(user.uid).get()
        .then(userDocCustomer => {
          var projectid = this.$route.params.project ? this.$route.params.project : this.idsite.projectid ? this.idsite.projectid : this.$route.params.project
          var siteid = this.$route.params.id ? this.$route.params.id : this.idsite.id ? this.idsite.id : this.$route.params.id

          var atomCustomerId = userDocCustomer.data().customerId ? userDocCustomer.data().customerId : ''
          if(atomCustomerId) {
            this.loadingInvoices = true
          }

          // console.log(projectid, siteid);
          db.collection('Users').doc(user.uid).collection('projects').doc(projectid).get()
          .then(eleUserProject => {
            db.collection('Projects').doc(projectid).collection('sites').doc(siteid).get()
            .then(site => {
                if(site.exists) {
                    var downgrade_date = site.data().downgrade_date ? site.data().downgrade_date : false
                    var downgrade_planed = false
                    if(downgrade_date) {
                      // console.log(statusCancel, site.data().due*1000);
                      var dueCancel = moment(downgrade_date*1000)
                      var dateActual = moment()

                      // Diferencia en días
                      var differenceInMinutes = dueCancel.diff(dateActual, 'minutes');
                      // console.log(differenceInMinutes, dueCancel, dateActual);
                      if(differenceInMinutes < 0) {
                        downgrade_planed = false
                        db.collection('Projects').doc(eleUserProject.id).collection('sites').doc(site.id).update({
                          downgrade_date: ''
                        })
                      } else {
                        downgrade_planed = true
                      }
                    }

                    this.planType = site.data().planType ? parseInt(site.data().planType, 10) : ''
                    this.planSite = site.data().plan ? site.data().plan : ''
                    this.paidRecurrency = site.data().paidRecurrency ? site.data().paidRecurrency : ''
                    this.nextDue = site.data().due_end ? moment(site.data().due_end*1000).format('MM/DD/YYYY') : site.data().due ? moment(site.data().due*1000).add(1, 'month').format('MM/DD/YYYY') : moment(Date.now()).add(1, 'month').format('MM/DD/YYYY')
                    this.nextDueCancel = site.data().due_end ? moment(site.data().due_end*1000).format('ll') : site.data().due ? moment(site.data().due*1000).add(1, 'month').format('ll') : moment(Date.now()).add(1, 'month').format('ll')
                    this.cancelSub = site.data().cancelSub ? site.data().cancelSub : false
                    this.subscriptionId = site.data().subscriptionId ? site.data().subscriptionId : ''
                    this.pages = site.data().planType ? site.data().planType : ''
                    this.downgrade_planed = downgrade_planed

                    // console.log(this.subscriptionId);
                    if(this.subscriptionId || atomCustomerId) {
                      let configpayments = {
                        method: 'post',
                        url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/get-payments-methods',
                        headers: { 
                          'Content-Type': 'application/json'
                        },
                        data: JSON.stringify({customerId: atomCustomerId})
                      };
                  
                      this.axios(configpayments)
                      .then((responsepayments) => {
                        // console.log(responsepayments.data.paymentMethods);
                        var responsePaymentsArray = responsepayments.data.paymentMethods.data
                        var atomArrayPayments = []
                        responsePaymentsArray.forEach(ele => {
                          atomArrayPayments.push({
                            brand: ele.card.brand,
                            last4: ' •••• ' + ele.card.last4,
                            exp_month: ele.card.exp_month,
                            exp_year: ele.card.exp_year,
                          })
                        })

                        this.arrayPayments = atomArrayPayments

                        if(this.subscriptionId) {
                          let config = {
                            method: 'post',
                            // url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/get-customer-invoices',
                            url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/get-customer-invoices-subscription',
                            headers: { 
                              'Content-Type': 'application/json'
                            },
                            data: JSON.stringify({customerId: atomCustomerId, subscriptionId: this.infoLicense.subscriptionId})
                          };
                      
                          this.axios(config)
                          .then((response) => {
                            // console.log(response.data);
                            var resonseArray = response.data.invoices.data
                            var atomArray = []
                            // if(resonseArray.length === 0) {
                            //   this.arrayPayments = []
                            // }

                            resonseArray.forEach(ele => {
                              // console.log(ele);
                              var detailLine = ele.lines && ele.lines.data.length ? ele.lines.data[0].description : ''
                              var detail = ele.subscription_details && ele.subscription_details.metadata ? ele.subscription_details.metadata.plan : ''
                              if(detailLine || detail) {
                                atomArray.push({
                                  created: ele.created ? moment(ele.created*1000).format('ll') : '',
                                  amount: ele.total ? '$' + parseFloat((ele.total/100)) : '$0',
                                  // amount: ele.amount_due ? '$' + parseFloat((ele.amount_due/100)) : '$0',
                                  paid: ele.paid,
                                  detail: detailLine ? detailLine : detail,
                                  url: ele.hosted_invoice_url
                                })
                              }
                            })
                            this.arrayInvoices = atomArray

                            this.loadingInvoices = false
                          })
                          .catch(e => {
                            this.loadingInvoices = false
                            console.log(e.message);
                          })
                        } else {
                          this.loadingInvoices = false
                        }
                      })
                      .catch(e => {
                        this.loadingInvoices = false
                        console.log(e.message);
                      })
                    } else {
                      this.arrayInvoices = []
                      this.arrayPayments = []
                      this.loadingInvoices = false
                    }

                    if(site.data().plan === 'free' || site.data().plan === 'Free' || !site.data().plan) {
                      this.infoLicense = {
                        id: site.id,
                        img: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                        name: site.data().name ? site.data().name : site.data().WebsiteURL,
                        domainFixed: site.data().WebsiteURL,
                        project: eleUserProject.data().name,
                        statusSite: site.data().status,
                        domain: site.data().homepage,
                        projectid: eleUserProject.data().id,
                        color: eleUserProject.data().color ? eleUserProject.data().color : 'rgb(240, 241, 247)',
                        own: 'me',
                        invitation: '',
                        imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                        plan: 'Free',
                        permission: 'owner',
                        amount: 'free',
                        nextDue: site.data().due_end ? moment(site.data().due_end*1000).format('MM/DD/YYYY') : site.data().due ? moment(site.data().due*1000).add(1, 'month').format('MM/DD/YYYY') : moment(Date.now()).add(1, 'month').format('MM/DD/YYYY'),
                        nextDueCancel: site.data().due_end ? moment(site.data().due_end*1000).format('ll') : '-',
                        // nextDueCancel: site.data().due ? moment(site.data().due*1000).format('MMM DD') : '-',
                        cancelSub: site.data().cancelSub ? site.data().cancelSub : false,
                        status: 'active',
                        payments: 'View Invoice',
                        freeTrial: site.data().freeTrial ? site.data().freeTrial : false,
                        dateTrial: site.data().freeTrial ? (site.data().due ? moment(site.data().due*1000).add(5, 'days').format('MM/DD/YYYY') : moment(Date.now()).add(5, 'days').format('MM/DD/YYYY')) : '',
                        customerId: userDocCustomer.data().customerId ? userDocCustomer.data().customerId : '',
                        customerIdSite: site.data().customerId ? site.data().customerId : '',
                        subscriptionId: site.data().subscriptionId ? site.data().subscriptionId : '',
                        planType: site.data().planType ? parseInt(site.data().planType, 10) : '',
                        planSite: site.data().plan ? site.data().plan : '',
                        paidRecurrency: site.data().paidRecurrency ? site.data().paidRecurrency : ''
                      }
                    } else if(site.data().plan === 'basic' || site.data().plan === 'Basic' || site.data().plan === 'pro' || site.data().plan === 'Pro' || site.data().plan === 'custom' || site.data().plan === 'Custom') {
                      this.infoLicense = {
                        id: site.id,
                        img: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                        name: site.data().name ? site.data().name : site.data().WebsiteURL,
                        domainFixed: site.data().WebsiteURL,
                        project: eleUserProject.data().name,
                        statusSite: site.data().status,
                        domain: site.data().homepage,
                        projectid: eleUserProject.data().id,
                        color: eleUserProject.data().color ? eleUserProject.data().color : 'rgb(240, 241, 247)',
                        own: 'me',
                        invitation: '',
                        imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                        plan: 'Pro',
                        permission: 'owner',
                        amount: '$20.00',
                        nextDue: site.data().due_end ? moment(site.data().due_end*1000).format('MM/DD/YYYY') : site.data().due ? moment(site.data().due*1000).add(1, 'month').format('MM/DD/YYYY') : moment(Date.now()).add(1, 'month').format('MM/DD/YYYY'),
                        nextDueCancel: site.data().due_end ? moment(site.data().due_end*1000).format('ll') : '-',
                        cancelSub: site.data().cancelSub ? site.data().cancelSub : false,
                        status: 'active',
                        payments: 'View Invoice',
                        freeTrial: site.data().freeTrial ? site.data().freeTrial : false,
                        dateTrial: site.data().freeTrial ? (site.data().due ? moment(site.data().due*1000).add(5, 'days').format('MM/DD/YYYY') : moment(Date.now()).add(5, 'days').format('MM/DD/YYYY')) : '',
                        customerId: userDocCustomer.data().customerId ? userDocCustomer.data().customerId : '',
                        customerIdSite: site.data().customerId ? site.data().customerId : '',
                        subscriptionId: site.data().subscriptionId ? site.data().subscriptionId : '',
                        planType: site.data().planType ? parseInt(site.data().planType, 10) : '',
                        planSite: site.data().plan ? site.data().plan : '',
                        paidRecurrency: site.data().paidRecurrency ? site.data().paidRecurrency : '',
                        pages: site.data().planType ? site.data().planType : ''
                      }
                    }
                }
            })
          })
        })
      } else {
        document.body.style.overflow = 'auto'
        this.loadingInvoices = false
      }
    }
  },
  validations: {
  },
  methods: {
    getInfoLicense() {
      localStorage.setItem('jsonInfoLic', JSON.stringify(this.infoLicense))
      this.submitEditar()
    },
    getInfoCancelLicense() {
      localStorage.setItem('jsonInfoCancel', JSON.stringify(this.infoLicense))
      this.submitEditar()
    },
    getInfoReactivateLicense() {
      localStorage.setItem('jsonInfoReactivate', JSON.stringify(this.infoLicense))
      this.submitEditar()
    },
    getPlanAmount(annualyBill,customPlan,typeCrawls) {
      //  paidRecurrency === 'year' ? (planType === 5 ? '$'+123 : planType === 10 ? '$'+140 : planType === 25 ? '$'+195 : planType >= 50 ? '$'+(220 + (planType - 50)) : '$'+20) : (planType === 5 ? '$'+5 : planType === 10 ? '$'+8 : planType === 25 ? '$'+10 : planType >= 50 ? '$'+(12 + ((planType - 50) / 10)) : '$'+20) }}.00
      var typeCrawl = 0
      if(typeCrawls === 5) {
        typeCrawl = 1
      } else if(typeCrawls === 10) {
        typeCrawl = 2
      } else if(typeCrawls === 25) {
        typeCrawl = 3
      } else {
        typeCrawl = 4
      }

      var priceId = ''
      if(annualyBill === 'year') {
        // yearly
        if(typeCrawl === 1 || typeCrawl === '1') {
          priceId = '$' + 19
        } else if(typeCrawl === 2 || typeCrawl === '2') {
          priceId = '$' + 29
        } else if(typeCrawl === 3 || typeCrawl === '3') {
          priceId = '$' + 49
        } else if(typeCrawl === 4 || typeCrawl === '4') {
          if((220 + (customPlan - 50)) === 220) {
            priceId = '$' + 69
          } else if((220 + (customPlan - 50)) === 270) {
            priceId = '$' + 99
          } else if((220 + (customPlan - 50)) === 320) {
            priceId = '$' + 139
          } else if((220 + (customPlan - 50)) === 370) {
            priceId = '$' + 179
          } else if((220 + (customPlan - 50)) === 420) {
            priceId = '$' + 219
          } else if((220 + (customPlan - 50)) === 470) {
            priceId = '$' + 259
          } else if((220 + (customPlan - 50)) === 520) {
            priceId = '$' + 299
          } else if((220 + (customPlan - 50)) === 570) {
            priceId = '$' + 339
          } else if((220 + (customPlan - 50)) === 620) {
            priceId = '$' + 379
          } else if((220 + (customPlan - 50)) === 670) {
            priceId = '$' + 419
          } else if((220 + (customPlan - 50)) === 720) {
            priceId = '$' + 459
          } else if((220 + (customPlan - 50)) === 770) {
            priceId = '$' + 499
          } else if((220 + (customPlan - 50)) === 820) {
            priceId = '$' + 539
          } else if((220 + (customPlan - 50)) === 870) {
            priceId = '$' + 579
          } else if((220 + (customPlan - 50)) === 920) {
            priceId = '$' + 619
          } else if((220 + (customPlan - 50)) === 970) {
            priceId = '$' + 659
          } else if((220 + (customPlan - 50)) === 1020) {
            priceId = '$' + 699
          } else if((220 + (customPlan - 50)) === 1070) {
            priceId = '$' + 739
          } else if((220 + (customPlan - 50)) === 1120) {
            priceId = '$' + 779
          } else if((220 + (customPlan - 50)) === 1170) {
            priceId = '$' + 819
          }
        }
      } else {
        // monthly
        if(typeCrawl === 1 || typeCrawl === '1') {
          priceId = '$' + 25
        } else if(typeCrawl === 2 || typeCrawl === '2') {
          priceId = '$' + 39
        } else if(typeCrawl === 3 || typeCrawl === '3') {
          priceId = '$' + 65
        } else if(typeCrawl === 4 || typeCrawl === '4') {
          if((12 + ((customPlan - 50) / 10)) === 12) {
            priceId = '$' + 89
          } else if((12 + ((customPlan - 50) / 10)) === 17) {
            priceId = '$' + 129
          } else if((12 + ((customPlan - 50) / 10)) === 22) {
            priceId = '$' + 179
          } else if((12 + ((customPlan - 50) / 10)) === 27) {
            priceId = '$' + 229
          } else if((12 + ((customPlan - 50) / 10)) === 32) {
            priceId = '$' + 279
          } else if((12 + ((customPlan - 50) / 10)) === 37) {
            priceId = '$' + 329
          } else if((12 + ((customPlan - 50) / 10)) === 42) {
            priceId = '$' + 379
          } else if((12 + ((customPlan - 50) / 10)) === 47) {
            priceId = '$' + 429
          } else if((12 + ((customPlan - 50) / 10)) === 52) {
            priceId = '$' + 479
          } else if((12 + ((customPlan - 50) / 10)) === 57) {
            priceId = '$' + 529
          } else if((12 + ((customPlan - 50) / 10)) === 62) {
            priceId = '$' + 579
          } else if((12 + ((customPlan - 50) / 10)) === 67) {
            priceId = '$' + 629
          } else if((12 + ((customPlan - 50) / 10)) === 72) {
            priceId = '$' + 679
          } else if((12 + ((customPlan - 50) / 10)) === 77) {
            priceId = '$' + 729
          } else if((12 + ((customPlan - 50) / 10)) === 82) {
            priceId = '$' + 779
          } else if((12 + ((customPlan - 50) / 10)) === 87) {
            priceId = '$' + 829
          } else if((12 + ((customPlan - 50) / 10)) === 92) {
            priceId = '$' + 879
          } else if((12 + ((customPlan - 50) / 10)) === 97) {
            priceId = '$' + 929
          } else if((12 + ((customPlan - 50) / 10)) === 102) {
            priceId = '$' + 979
          } else if((12 + ((customPlan - 50) / 10)) === 107) {
            priceId = '$' + 999
          }
        }
      }
      // console.log(priceId);

      return priceId
    },
    submitEditar(payload) {
      this.$refs.sidebar_info_subscription.hide()
    },
    redirectToBilling(customerId) {
      // this.processInvoice = customerId
      // this.processInvoice = idsite
      this.processInvoice = true
      var URLactual = new URL(window.location)
      var domain = URLactual.protocol + '//' + (URLactual.hostname === 'localhost' ? URLactual.hostname + ':8080' : URLactual.hostname)

      let config = {
        method: 'post',
        // maxBodyLength: Infinity,
        url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/create-billing-session',
        headers: { 
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({customerId: customerId, domain: domain})
      };
  
      this.axios(config)
      .then((response) => {
        // Redirigir al usuario a la URL de facturación de Stripe
        var url = response.data.url
        if(url) {
          window.location.href = url

          setTimeout(() => {
            this.processInvoice = ''
          }, 1500)
        }
      })
      .catch(e => console.log(e.message))
    },
    async loadStripeCard() {
      this.stripe = await loadStripe('pk_test_51PVKa9Eha01YHD9pDFl3O2hsGF5KgINCA5fooZqjlbDqfmmrvWwABRufNHVDdGdl643Wyv3VL0vZuYazlAQrBDeu00sCy4UCuP');
      const elements = this.stripe.elements();
      this.cardElement = elements.create('card', {
        style: {
          base: {
            fontSize: '16px',
            color: '#32325d',
            '::placeholder': {
              color: '#aab7c4',
            },
          },
        },
        hidePostalCode: true
        // placeholder: {
        //   number: '1234 1234 1234 1234',
        //   expiry: 'MM / YY',
        //   cvc: 'CVC',
        //   postal: 'ZIP Code'
        // }
      });
      this.cardElement.mount('#card-element');
    },
    async handleSubmitCC() {
      // console.log('trigger 1',this.cardElement);
      this.processSubmitCard = true
      // Crear un token o PaymentMethod con los datos de la tarjeta
      setTimeout(() => {
        // console.log(paymentMethod.id);
        // if(!paymentMethod.id) {
          this.processSubmitCard = false
        // }
      }, 2500);
      const { paymentMethod, error } = await this.stripe.createPaymentMethod({
        type: 'card',
        card: this.cardElement,
      });

      if(error) {
        // console.log('object1');
        console.error(error);
        // this.showUpdateCards = false
        this.processSubmitCard = false

        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.code === 'validation_error' ? 'Incorrect data' : error.code === 'invalid_expiry_year_past' ? 'The card expiration year is wrong' : error.code === 'invalid_number' ? 'Your card number is not valid' : error.code === 'incomplete_number' ? 'Your card number is incomplete' : error.code === 'incomplete_zip' ? 'Your zip code is incomplete' : 'Incorrect data',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
      } else {
        // console.log('object2');
        // Enviar el paymentMethod.id a tu backend
        this.savePaymentMethod(paymentMethod.id);
        // this.processSubmitCard = true
      }
    },
    async savePaymentMethod(paymentMethodId) {
      // console.log('trigger 2');
      let config = {
        method: 'post',
        // maxBodyLength: Infinity,
        url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/save-payment-method',
        headers: { 
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({ paymentMethodId: paymentMethodId, customerId: this.infoLicense.customerId })
      };
  
      this.axios(config)
      .then((response) => {
        // console.log(response.data, response.data.status);
        if(response.status === 200) {
          this.submitEditar()
          setTimeout(() => {
            this.processSubmitCard = false
            this.showUpdateCards = false
            // console.log(JSON.stringify(response))
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Payment method added successfully',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          }, 2000)
        } else {
          // console.log(e.message);
          this.processSubmitCard = false
          this.showUpdateCards = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to add payment method',
              icon: 'BellIcon',
              variant: 'warning',
            },
          })
        }
      })
      .catch(e => {
        console.log(e.message);
        this.processSubmitCard = false
        this.showUpdateCards = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed to add payment method',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
      })
    }
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
.link-invoice-info-subscription:hover {
  background: #f2f2f2 !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-right-subscription {
    width: 90%;
    max-width: 593px !important;
}
</style>